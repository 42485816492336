.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 2vh;
  height: 100vh;
}

.App-logo {
  height: 50vmin;
}

.App-header {
  background-color: #ffffff;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(0, 0, 0);
}

.App-body {
  background-color: #ffffff;
  min-height: 10vh;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
  font-size: calc(5px + 2vmin);
}

.App-link {
  color: #09d3ac;
}

.navbar,
.navbar-default {
    background-color: #fecb31 !important;
}